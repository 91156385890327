import React from 'react'

import { ActivityTrackerItem, LinkProps } from '@guiker/react-framework'
import { User } from '@guiker/shared-framework'

import { useInvoiceContext } from '../../hooks'
import { PayerContent } from './PayerContent'
import { ReceiverContent } from './ReceiverContent'
import { AdminAccessLink } from './utils'

type InvoiceContentProps = {
  user?: User
  activityLogItems?: ActivityTrackerItem[]
  customerLinkProps?: LinkProps
  topActions?: React.ReactNode
  adminAccessLinks?: AdminAccessLink
}

export const InvoiceContent: React.FC<InvoiceContentProps> = ({
  adminAccessLinks,
  user,
  activityLogItems,
  customerLinkProps,
  topActions,
}) => {
  const { invoice } = useInvoiceContext()

  const isPayer = invoice?.customer.id === user?.id

  if (!invoice) return <></>

  return isPayer ? (
    <PayerContent />
  ) : (
    <ReceiverContent
      customerLinkProps={customerLinkProps}
      topActions={topActions}
      activityLogItems={activityLogItems}
      adminAccessLinks={adminAccessLinks}
    />
  )
}
