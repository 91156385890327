import React, { PropsWithChildren } from 'react'

import { Invoice, PayInMethod, PayoutMethod } from '@guiker/payment-shared'
import {
  ActivityTracker,
  ActivityTrackerItem,
  Flex,
  H2,
  Link,
  LinkProps,
  PageSection2,
  PageSection4,
  PSmaller,
  useDateFormatter,
} from '@guiker/react-framework'
import { currency as baseCurrency, money } from '@guiker/shared-framework'

import { useT } from '../../i18n'
import { BreakdownItem } from '../../utils'
import { AdminAccessLink } from '../InvoiceContent'
import { InvoiceReason } from '../InvoiceReason'
import { FailReason, InvoiceDetailInfo, InvoiceDetailStatusChip, InvoiceItem, WireTransferNotice } from './components'

type ViewInvoiceDetailProps = PropsWithChildren & {
  invoice: Invoice
  payInMethod?: PayInMethod
  breakdownItems: BreakdownItem[]
  totalAmount: number
  isHistoryOpen?: boolean
  customerLinkProps?: LinkProps
  topActions?: React.ReactNode
  payoutMethod?: PayoutMethod
  activityLogItems?: ActivityTrackerItem[]
  adminAccessLinks?: AdminAccessLink
}

export const ViewInvoiceDetail: React.FC<ViewInvoiceDetailProps> = ({
  children,
  payoutMethod,
  invoice,
  payInMethod,
  breakdownItems,
  totalAmount,
  activityLogItems,
  customerLinkProps,
  topActions,
  adminAccessLinks,
}) => {
  const { tShared } = useT({})
  const { formatDate } = useDateFormatter()
  const { customer, currency, createdAt } = invoice

  return (
    <PageSection2
      title={
        <Flex flexDirection='column'>
          <Flex gap={2}>
            <H2 mb={0}>{tShared(`paymentDetails`)}</H2>
            <InvoiceDetailStatusChip invoice={invoice} withDate />
          </Flex>
          {adminAccessLinks?.providerLink?.payment && (
            <Link underline isExternalLink target='_blank' to={adminAccessLinks.providerLink.payment.to}>
              <PSmaller>{adminAccessLinks.providerLink.payment.value}</PSmaller>
            </Link>
          )}
        </Flex>
      }
      topActions={topActions}
    >
      <FailReason />
      <WireTransferNotice />
      <InvoiceDetailInfo
        customer={customer}
        customerLinkProps={customerLinkProps}
        issueDate={formatDate(invoice.dueBy || createdAt)}
        invoiceFor={<InvoiceReason invoice={invoice} />}
        payInMethod={payInMethod}
        payoutMethod={payoutMethod}
        adminAccessLinks={adminAccessLinks}
      />

      <PageSection4 title={tShared(`breakdown.header`)}>
        <Flex flexDirection='column' gap={1}>
          {breakdownItems.map(({ label, price, color = 'textPrimary' }) => (
            <InvoiceItem label={label} value={price} color={color} key={label} />
          ))}
          <InvoiceItem
            bold
            label={tShared(`breakdown.totalAmount`)}
            value={money.fromAmount(totalAmount, baseCurrency[currency]).toString(true)}
          />
        </Flex>
      </PageSection4>

      {!!activityLogItems?.length && (
        <PageSection4 title={tShared(`activityLog.header`)}>
          <ActivityTracker items={activityLogItems} />
        </PageSection4>
      )}
      {children}
    </PageSection2>
  )
}
