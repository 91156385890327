import React, { useEffect, useState } from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { ButtonWithLoader, SecondaryButton } from '@guiker/components-library'
import { compact } from '@guiker/lodash'
import { CountryCode } from '@guiker/payment-verification-shared'
import { useMutation } from '@guiker/react-query'
import { useSentryContext } from '@guiker/sentry-context'
import { StripeProvider, useAuthenticatedPaymentVerificationApiClient, useStripe } from '@guiker/stripe-context'

type StripeLinkProps = {
  label: string
  onSelect: (args: { publicToken: string; accountId: string }) => void
  countryCode: CountryCode
}

const sentryMessage = 'Stripe::ConfirmAcssDebitSetup'

const Content: React.FC<StripeLinkProps> = ({ label, countryCode, onSelect }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [accountId, setAccountId] = useState<string>()
  const [acssSetupSecret, setAcssSetupSecret] = useState<string>()
  const { captureMessage } = useSentryContext()
  const { user } = useAuthenticationContext()

  const apiClient = useAuthenticatedPaymentVerificationApiClient()
  const stripe = useStripe()

  const { mutate: generateAcssSetupSecret, isLoading: isGenerating } = useMutation(
    () => {
      return apiClient.createStripeLinkToken({ payload: { countryCode } })
    },
    {
      onSuccess: (res) => {
        setIsLoading(true)
        setAcssSetupSecret(res)
      },
    },
  )

  useEffect(() => {
    if (acssSetupSecret) {
      captureMessage({ message: sentryMessage, messageInfo: { acssSetupStarted: 'loaded' } })
      stripe.client
        .confirmAcssDebitSetup(acssSetupSecret, {
          payment_method: {
            acss_debit: {
              institution_number: '000',
              transit_number: '11000',
              account_number: '000123456789',
            },
            billing_details: {
              name: compact([user.firstName, user.lastName]).join(' '),
              email: user.emailAddress,
            },
          },
        })
        .then(({ error, setupIntent }) => {
          if (error) {
            captureMessage({ message: sentryMessage, messageInfo: error as Record<string, any> })
            return
          } else if (setupIntent.status !== 'succeeded') {
            captureMessage({ message: sentryMessage, messageInfo: setupIntent as Record<string, any> })
            return
          }
          setAccountId(setupIntent.id)
        })
        .catch((error) => {
          captureMessage({ message: sentryMessage, messageInfo: error })
        })
        .finally(() => setIsLoading(false))
    }
  }, [acssSetupSecret])

  useEffect(() => {
    accountId && onSelect({ accountId, publicToken: acssSetupSecret })
  }, [accountId, acssSetupSecret])

  return (
    <ButtonWithLoader
      type='button'
      variant='outlined'
      isLoading={isGenerating || isLoading}
      buttonComponent={SecondaryButton}
      onClick={() => generateAcssSetupSecret()}
    >
      {label}
    </ButtonWithLoader>
  )
}

const StripeLink: React.FC<StripeLinkProps> = (props) => {
  return (
    <StripeProvider region={props.countryCode}>
      <Content {...props} />
    </StripeProvider>
  )
}

export { StripeLink }
