import { Geocoordinates, GeoSpatialLocation, SequentialId } from '@guiker/base-entity'

import { Address } from './address'
import { BuildingConfiguration, Configuration } from './configuration'
import { Construction } from './construction'
import { Document } from './document'

export enum BuildingTypes {
  COMMERCIAL = 'commercial',
  CONDO = 'condo',
  MIXED = 'mixed',
  PLEX = 'plex',
  SINGLE_FAMILY = 'single-family',
}

export type Building = {
  id: string
  externalId: string
  type: BuildingTypes
  address: Address
  geocoordinates: Geocoordinates
  construction?: Construction
  configuration: Configuration
} //deprecate

//////////

export type Picture = {
  id: string
  url?: string
  label?: string
  order?: number
}

export enum BuildingAmenities {
  CONCIERGE = 'CONCIERGE',
  BIKE_PARKING = 'BIKE_PARKING',
  COMMUNAL_OUTDOOR_SPACE = 'COMMUNAL_OUTDOOR_SPACE',
  ELEVATOR = 'ELEVATOR',
  GYM = 'GYM',
  LAUNDRY_ROOM = 'LAUNDRY_ROOM',
  STORAGE = 'STORAGE',
  INDOOR_PARKING = 'INDOOR_PARKING',
  OUTDOOR_PARKING = 'OUTDOOR_PARKING',
  POOL = 'POOL',
  RECREATION_ROOM = 'RECREATION_ROOM',
  SECURITY = 'SECURITY',
  WHEEL_CHAIR_ACCESSIBLE = 'WHEEL_CHAIR_ACCESSIBLE',
  JANITOR = 'JANITOR',
}

export type BuildingMeta = {
  legacyIds?: number[] //legacy listing ids from migration
  unitNumbers?: { unitNumber: string; listingId: number }[]
}

export type BuildingLocation = GeoSpatialLocation & {
  mapId?: string // google map id
  region: string // our operational cities; ie: montreal / boston / toronto ..etc
}

export type NEWBuilding = SequentialId & {
  id: string
  location: BuildingLocation
  mailingAddress: Address
  addresses?: Address[]
  amenities?: BuildingAmenities[]
  pictures?: Picture[]
  documents?: Document[]
  meta?: BuildingMeta
  construction?: Construction
  configuration?: BuildingConfiguration
}
