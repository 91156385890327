export enum ListingEventTypes {
  TOUCHED = 'TOUCHED',
}

export enum ListingInquiryEventTypes {
  UNIT_MANAGERS_REPLACED = 'UNIT_MANAGERS_REPLACED',
}

export enum ListingUserEventTypes {
  OWNERSHIP_TRANSFERRED = 'OWNERSHIP_TRANSFERRED',
}
